import React from 'react';
import SEO from '../components/seo';
import Header from '../components/header';

const Imprint = () => (
	<>
		<Header />

		<div style={{ maxWidth: '1064px', padding: '32px', paddingTop: '112px', margin: '0 auto' }}>
			<SEO title="Impressum" />

			<h1>Impressum</h1>
			<p>
				THNG GmbH
				<br />
				Römerstraße 75
				<br />
				<br />
				D-71229 Leonberg
				<br />
				<br />
				Amtsgericht Stuttgart | HRB 776024
				<br />
				USt-IdNr.: DE335956615
				<br />
				Geschäftsführer: Joshua Raabe
				<br />
				<br />
				Telefon:+49 (0)7152 906 599 0
				<br />
				<br />
				Genderhinweis
				<br />
				<br />
				Wir legen großen Wert auf Diversität und Gleichbehandlung. Im Sinne einer besseren Lesbarkeit der
				Informationen wurde jedoch von uns oftmals entweder die maskuline oder feminine Form gewählt.
				<br />
				Dies impliziert keinesfalls eine Benachteiligung des jeweils anderen Geschlechts. Wenn wir also z.B. von
				Mitarbeitern, Teilnehmern und Trainern sprechen, meinen wir selbstverständlich auch Mitarbeiterinnen,
				Teilnehmerinnen und Trainerinnen.
				<br />
				<br />
				Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
				externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
				<br />
				<br />
				Wir danken für Ihr Verständnis
			</p>
		</div>
	</>
);

export default Imprint;
